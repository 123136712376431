import React, { useState, useEffect } from 'react';
import usePermissions from 'hooks/usePermissions';
import { Link } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/outline';
import TabNav from 'components/TabNav';
import LeadSourcesTable from './LeadSourcesTable';
import { getNameInitials, percentageFormatter } from 'utils/formatters';
import { LEADS_POD_TAP, PITCHER_POD_TAP } from '../utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { toFinite } from 'lodash';
import { Modal } from 'components';
import ModalHeader from 'components/ModalHeader';
import LeadsOfRepTable from './LeadsOfRepTable';

const MetricsTable = ({ initialDateLoaded }) => {
  const { userCan } = usePermissions();
  const [leadRepsData, setLeadRepsData] = useState([]);
  const { startDate, endDate } = useSelector((state) => state.datePicker.range);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (initialDateLoaded) {
      getLeadsMetrics();
    }
  }, [startDate, endDate]);

  const getLeadsMetrics = async () => {
    let params = {
      startDate: moment(startDate).tz(moment.tz.guess()).startOf('d').format(),
      endDate: moment(endDate).tz(moment.tz.guess()).endOf('d').format(),
    };

    await axios
      .get(`/agency/leads/leadsRep`, {
        params,
      })
      // .get(
      //   `/agency/leads/leadsRep?startDate=2025-01-01T00:00:00%2B08:00&endDate=2025-01-22T23:59:59%2B08:00`
      // )
      .then((res) => {
        setLeadRepsData(res.data.data);
      });
  };

  const getCount = (data, field) => {
    if (!Array.isArray(data)) throw new Error('Data must be an array');
    return data.find((el) => el.key === field)
      ? data.find((el) => el.key === field)?.count
      : 0;
  };

  return (
    <>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white">
              {leadRepsData?.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-300 col-span-12">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        rowSpan="2"
                        className="py-3.5 pl-4 pr-3 text-left text-lg font-semibold text-gray-900 sm:pl-6"
                      >
                        Lead Rep
                      </th>
                      <th
                        colSpan="4"
                        className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-center border-b"
                      >
                        Processed
                      </th>
                      <th
                        rowSpan="2"
                        className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-center"
                      >
                        Unqualified
                      </th>
                      <th
                        colSpan="4"
                        className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-center border-b"
                      >
                        Pitched
                      </th>
                      <th
                        colSpan="3"
                        className="px-3 py-3.5 text-lg font-semibold text-gray-900 text-center border-b"
                      >
                        Booked
                      </th>
                    </tr>
                    <tr>
                      <th className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                        New Lead
                      </th>
                      <th className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                        UNL
                      </th>
                      <th className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                        Old Lead
                      </th>
                      <th className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                        Total Processed
                      </th>
                      <th className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                        New Lead
                      </th>
                      <th className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                        UNL
                      </th>
                      <th className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                        Old Lead
                      </th>
                      <th className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                        Total Pitched
                      </th>
                      <th className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                        Direct Booking
                      </th>
                      <th className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                        Call-Booked
                      </th>
                      <th className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center">
                        Total Bookings
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {leadRepsData.map((el) => (
                      <tr key={el.key}>
                        <td className="flex felx-row break-words py-4 pl-4 pr-3 text-base font-semibold text-gray-900 sm:pl-6">
                          <div>
                            <span className="flex flex-col justify-center bg-blue-500 text-white w-7 h-7 text-base rounded-3xl uppercase text-center">
                              {el.value.length > 0
                                ? getNameInitials(
                                    el.value[0].firstName,
                                    el.value[0].lastName
                                  )
                                : ''}
                            </span>
                          </div>
                          <div className="flex flex-col justify-center pl-2">
                            {el.value.length > 0 ? el.value[0].firstName : ''}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {getCount(el.value, 'newLeads')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {getCount(el.value, 'unprocessed')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {getCount(el.value, 'oldLeads')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {toFinite(getCount(el.value, 'newLeads')) +
                            toFinite(getCount(el.value, 'unprocessed')) +
                            toFinite(getCount(el.value, 'oldLeads'))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {toFinite(getCount(el.value, 'newLeads')) +
                            toFinite(getCount(el.value, 'unprocessed')) +
                            toFinite(getCount(el.value, 'oldLeads')) -
                            (toFinite(getCount(el.value, 'newLeadsPitcher')) +
                              toFinite(
                                getCount(el.value, 'unprocessedPitcher')
                              ) +
                              toFinite(getCount(el.value, 'oldLeadsPitcher')))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {getCount(el.value, 'newLeadsPitcher')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {getCount(el.value, 'unprocessedPitcher')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {getCount(el.value, 'oldLeadsPitcher')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {toFinite(getCount(el.value, 'newLeadsPitcher')) +
                            toFinite(getCount(el.value, 'unprocessedPitcher')) +
                            toFinite(getCount(el.value, 'oldLeadsPitcher'))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {getCount(el.value, 'directBooking')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {getCount(el.value, 'callBooked')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500 text-center">
                          {toFinite(getCount(el.value, 'directBooking')) +
                            toFinite(getCount(el.value, 'callBooked'))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="p-10">Nothing to display here!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MetricsTable;
