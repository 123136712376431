import ClientList from './components/ClientList';

const AllClients = () => {
  return (
    <ClientList
      title="All Clients"
      initialFilters={[
        {
          attribute: 'status',
          operator: 'IN',
          value: ['ACTIVE', 'INACTIVE'],
        },
      ]}
      defaultColumns={[
        'client',
        'status',
        'service',
        'subscription',
        'brandStrategist',
        'accountSpecialist',
        'contractSigned',
        'subscriptionPaymentOption',
        'terminatedAt',
        'subscription.amount',
      ]}
      allowedFilters={[
        'status',
        'invoiceSummary',
        'accountStatus',
        'subscriptionStatus',
        'terminatedAt',
        'contractSigned',
        'terminationReason',
        'brandStrategist',
        'accountSpecialist',
        'subscriptionPaymentOption',
      ]}
      initialParams={{
        page: 1,
        pageSize: 10,
        sorts: 'client:asc',
        search: '',
        filters: '[]',
        include: [
          'subscription',
          'defaultContact',
          'projectManager[user[avatar]]',
          'brandStrategist[user[avatar]]',
          'accountSpecialist[user[avatar]]',
          'termination[requestor[avatar],evaluator[avatar]]',
        ],
        attributes: [
          'phone',
          'client',
          'status',
          'service',
          'address',
          'website',
          'terminatedAt',
          'accountStatus',
          'amazonPageUrl',
          'agencyClientId',
          'draftMarketplace',
          'contractSigned',
          'salesClientId',
          'salesClientId',
          'subscriptionPaymentOption',
          'totalPaidInvoiceAmount',
          'totalUnPaidInvoiceAmount',
        ],
      }}
    />
  );
};

export default AllClients;
