import { NavLink } from 'react-router-dom';
import ClientList from './components/ClientList';

const Terminated = ({ invoiceSummary }) => {
  return (
    <ClientList
      title="Terminated Clients"
      initialFilters={[
        {
          attribute: 'terminatedAt',
          operator: 'HAS_PROPERTY',
        },
        {
          attribute: 'invoiceSummary',
          operator: 'EQ',
          value: invoiceSummary,
        },
      ]}
      excludeColumns={['agencyClientId']}
      defaultColumns={[
        'client',
        'terminatedAt',
        'invoiceSummary',
        'terminationReason',
        'termination.createdAt',
        'termination.requestor',
        'termination.evaluator',
        invoiceSummary === 'PAID'
          ? 'totalPaidInvoiceAmount'
          : 'totalUnPaidInvoiceAmount',
      ]}
      initialParams={{
        page: 1,
        pageSize: 10,
        sorts: 'client:asc',
        search: '',
        filters: '[]',
        include: [
          'subscription',
          'defaultContact',
          'projectManager[user[avatar]]',
          'brandStrategist[user[avatar]]',
          'accountSpecialist[user[avatar]]',
          'termination[requestor[avatar],evaluator[avatar]]',
        ],
        attributes: [
          'phone',
          'client',
          'status',
          'service',
          'address',
          'website',
          'terminatedAt',
          'isOnboarding',
          'accountStatus',
          'amazonPageUrl',
          'agencyClientId',
          'draftMarketplace',
          'isSPAPIConnected',
          'isADVAPIConnected',
          'contractSigned',
          'terminationReason',
          'invoiceSummary',
          'salesClientId',
          'totalPaidInvoiceAmount',
          'totalUnPaidInvoiceAmount',
        ],
      }}
    >
      <div className="flex text-grayscale-700">
        <NavLink
          activeClassName="bg-white text-secondary-light"
          className="py-4 px-8 text-13 leading-1.5 font-bold"
          to="/billing/terminations"
          exact
        >
          Unpaid
        </NavLink>

        <NavLink
          activeClassName="bg-white text-secondary-light"
          className="py-4 px-8 text-13 leading-1.5 font-bold"
          to="/billing/terminations/paid"
          exact
        >
          Paid
        </NavLink>
      </div>
    </ClientList>
  );
};

export default Terminated;
